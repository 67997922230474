<template>
  <div class="joboffer-box" @click="navigateToJob">
    <v-row>
      <v-col cols="11">
        <div>
          <strong>
            {{ localize(joboffer.name) }}
          </strong>
        </div>
        <div class="d-block d-sm-flex mt-4 text-sm">
          <div
            v-if="
              isFieldEnabled('location') && localize(joboffer.location) !== ''
            "
            class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
          >
            <v-icon class="d-inline">mdi-map-marker</v-icon>
            <div class="d-inline-block">
              {{ localize(joboffer.location) }}
            </div>
          </div>
          <div
            v-if="
              isFieldEnabled('company-name') &&
              localize(joboffer.companyName) !== ''
            "
            class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
          >
            <v-icon class="d-inline">mdi-office-building-marker-outline</v-icon>
            <div class="d-inline-block">
              {{ localize(joboffer.companyName) }}
            </div>
          </div>

          <div
            v-if="
              isFieldEnabled('department') &&
              localize(joboffer.department) !== ''
            "
            class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
          >
            <v-icon class="d-inline">mdi-briefcase</v-icon>
            <div class="d-inline-block">
              {{ localize(joboffer.department) }}
            </div>
          </div>
          <div
            v-if="
              isFieldEnabled('working-time') &&
              localize(joboffer.workingTime) !== ''
            "
            class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
          >
            <v-icon class="d-inline">mdi-clock</v-icon>
            <div class="d-inline-block">
              {{ localize(joboffer.workingTime) }}
            </div>
          </div>

          <div
            v-if="
              isFieldEnabled('temp-job') && localize(joboffer.tempJob) !== ''
            "
            class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
          >
            <v-icon class="d-inline">mdi-file</v-icon>
            <div class="d-inline-block">
              {{ localize(joboffer.tempJob) }}
            </div>
          </div>
          <!-- <div
            class="joboffer-box__job-data ml-sm-8 mb-2 mb-sm-0 d-flex align-center"
          >
            <v-icon class="d-inline-block">mdi-information</v-icon>
            <div class="d-inline-block">
              {{ localize(joboffer.contractType) }}
              /
              {{ localize(joboffer.workingTime) }}
              /
              {{ localize(joboffer.tempJob) }}
            </div>
          </div> -->
        </div>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-end text-right">
        <v-icon color="accent">mdi-chevron-right</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    joboffer: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      showExternalAdvert: 'showExternalAdvert',
      jobDataFields: 'jobDataFields'
    })
  },

  methods: {
    navigateToJob() {
      if (this.showExternalAdvert) {
        window.open(this.joboffer.advertUrl, '_blank');
      } else {
        this.$router.push({
          name: 'jobDetails',
          params: {
            jobId: this.joboffer.jobofferId,
            jobName: 'joboffer-name'
          }
        });
      }
    },

    isFieldEnabled(fieldName) {
      return this.jobDataFields.includes(fieldName);
    }
  }
};
</script>

<style scoped>
.joboffer-box:hover {
  cursor: pointer;
}
</style>
