<template>
  <div>
    <!-- <hero-element :heroClaim="heroClaim"></hero-element> -->

    <v-main class="content-area">
      <v-container>
        <v-row>
          <v-col cols="12" class="job-details__header-image">
            <v-img
              src="https://hidden-professionals.de/UP/HPv3/CompanyFiles/CompanyID_38/AnnonceTemplates/Extended/images/Motiv2.JPG"
            >
              <v-overlay :absolute="absoluteOverlay">
                <div
                  class="job-details__header-image__title-wrapper text-center"
                >
                  <v-row>
                    <v-col cols="10" offset="1">
                      <h1>{{ heroClaim }}</h1>
                    </v-col>
                  </v-row>
                </div>
              </v-overlay>
            </v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <router-view v-slot="{ Component }">
              <transition name="fade">
                <component :is="Component" />
              </transition>
            </router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import sidebarFilter from '@/components/jobs/sidebar-filter.vue';
import heroElement from '@/components/hero-element.vue';

export default {
  props: {
    heroClaim: {
      type: String,
      required: true
    }
  },

  components: {
    sidebarFilter,
    heroElement
  },

  computed: {
    absoluteOverlay() {
      return true;
    }
  }
};
</script>
