<template>
  <div class="apply-form__section apply-form__documents">
    <h3>{{ $t('application.documents') }}</h3>
    <div :v-html="$t('application.documentsInfo')"></div>

    <v-row
      v-if="
        documentsSettings.letterValidationMode !==
        fieldValidationMode.NotPrompted
      "
      class="mt-4 border-bot"
    >
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-radio-group v-model="letterMode" hide-details="true" class="mt-0">
          <v-radio value="upload" :label="'Anschreiben hochladen'"></v-radio>
          <v-radio
            value="text"
            :label="'Anschreiben direkt als Text eingeben'"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-center">
        <!-- <div class="apply-form__documents__letter-text-button">
          <label class="text--gray">{{ $t('application.alternative') }}</label>
          <v-btn small color="primary">
            {{ $t('application.letterAsText') }}
          </v-btn>
        </div> -->
        <file-input
          v-if="letterMode === 'upload'"
          v-model="application.letter"
          :label="
            getLabel(
              'application.letter',
              documentsSettings.letterValidationMode
            )
          "
          :accept="allowedFileTypes"
          :rules="getValidationRules(documentsSettings.letterValidationMode)"
          :upload-endpoint="'application-file'"
          :download-endpoint="'application-file'"
          :additional-values="{ fileType: fileType.Letter }"
          :file-prefix="formId"
        ></file-input>

        <v-textarea
          v-else-if="letterMode === 'text'"
          v-model="application.letterText"
          hide-details="auto"
          solo
          flat
          :label="
            getLabel(
              'application.letter',
              documentsSettings.letterValidationMode
            )
          "
          :rules="getValidationRules(documentsSettings.letterValidationMode)"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row class="mt-md-0">
      <v-col
        v-if="
          documentsSettings.cvValidationMode !== fieldValidationMode.NotPrompted
        "
        cols="12"
        md="6"
      >
        <file-input
          v-model="application.cv"
          :label="
            getLabel('application.cv', documentsSettings.cvValidationMode)
          "
          :accept="allowedFileTypes"
          :rules="getValidationRules(documentsSettings.cvValidationMode)"
          :upload-endpoint="'application-file'"
          :download-endpoint="'application-file'"
          :additional-values="{ fileType: fileType.CV }"
          :file-prefix="formId"
          @file-uploaded="fileUploadedHandler"
        ></file-input>
      </v-col>

      <v-col
        v-if="
          documentsSettings.documentsValidationMode !==
          fieldValidationMode.NotPrompted
        "
        cols="12"
        md="6"
      >
        <file-input
          v-model="application.documents"
          multiple
          :label="
            getLabel(
              'application.additionalDocuments',
              documentsSettings.documentsValidationMode
            )
          "
          :accept="allowedFileTypes"
          :rules="getValidationRules(documentsSettings.documentsValidationMode)"
          :upload-endpoint="'application-file'"
          :download-endpoint="'application-file'"
          :additional-values="{ fileType: fileType.Document }"
          :file-prefix="formId"
        ></file-input>
      </v-col>
    </v-row>

    <div class="mt-4">
      <i class="text-sm text--muted">{{ $t('application.fileSizeInfo') }}</i>
    </div>

    <cv-parser-dialog v-model="parsing"></cv-parser-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

import cvParserDialog from './cv-parser-dialog.vue';

import fileInput from '../file-input.vue';
import fileTypeEnums from '@/enum/application-file-type.js';
import validationModeEnums from '@/enum/field-validation-mode.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    documentsSettings: {
      type: Object,
      required: true
    },

    jobofferId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    allowedFileTypes: 'image/*, .pdf, .docx, .doc',
    uploading: false,
    parsing: false,
    letterMode: 'upload'
  }),

  computed: {
    ...mapState('applicationModule', {
      formId: 'formId'
    }),

    application: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    cvFile() {
      return this.application.cv;
    },

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    },

    fileNotEmptyRule() {
      return [
        (v) => !!v || this.$t('application.fieldIsMandatory'),
        (v) => (v && v.length > 0) || this.$t('application.fieldIsMandatory')
      ];
    },

    fileType() {
      return fileTypeEnums.ApplicationFileTypes;
    }
  },

  methods: {
    ...mapActions('applicationModule', {
      uploadApplicationFile: 'uploadFile',
      parseCvFile: 'parseCvFile'
    }),

    async fileUploadedHandler() {
      if (this.cvFile.length > 0) {
        this.parsing = true;

        const cvDataRequest = {
          application: this.application,
          objectId: this.cvFile[0].objectName,
          formId: this.formId,
          jobofferId: this.jobofferId
        };

        let result = null;
        try {
          result = await this.parseCvFile(cvDataRequest);
          this.$emit('file-parsed', result);
        } catch {
          eventBus.$emit(
            'errorMessage',
            'Es ist ein Fehler beim Auslesen des Lebenslaufs aufgetreten'
          );
        }

        this.parsing = false;
      }
    },

    getLabel(labelKey, validationMode) {
      let label = this.$t(labelKey);
      if (validationMode === this.fieldValidationMode.Mandatory) {
        label += '*';
      }

      return label;
    },

    getValidationRules(validationMode) {
      let rules = [];

      // todo rules available for all files

      // add not empty rule for mandatory file uploads
      if (validationMode === this.fieldValidationMode.Mandatory) {
        rules = [...rules, ...this.fileNotEmptyRule];
      }

      return rules;
    },

    async uploadFile(file, fileType) {
      this.uploading = true;
      await this.uploadApplicationFile({
        file,
        fileType
      });

      this.uploading = false;
    }
  },

  components: {
    cvParserDialog,
    fileInput
  }
};
</script>
