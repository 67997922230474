export const ContentEnums = Object.freeze({
  componentTypes: {
    TextBox: 1,
    AdvertTextBox: 2,
    CompanyBenefits: 3,
    HtmlContent: 4,
    ContentGrid: 5,
    CompanyDepartments: 6,
    Expander: 7,
    Accordion: 8,
    Image: 9
  }
});
