export default {
  async getCompanySettings() {
    let currentUrl = window.location.href;
    currentUrl = currentUrl.replace('/career/#/', '/').replace('/#/', '/');

    return await fetch(
      `${process.env.VUE_APP_API_URL}/company/getsettings?url=${currentUrl}`
    );
  },

  getDepartments(slug) {
    return fetch(
      `${process.env.VUE_APP_API_URL}/company/getdepartments/${slug}`
    );
  },

  getImprint(slug) {
    return fetch(`${process.env.VUE_APP_API_URL}/company/getImprint/${slug}`);
  },

  getLocations(slug) {
    return fetch(`${process.env.VUE_APP_API_URL}/company/getlocations/${slug}`);
  },

  getPrivacyPolicy(slug) {
    return fetch(
      `${process.env.VUE_APP_API_URL}/company/getPrivacyPolicy/${slug}`
    );
  }
};
