export default {
  setCompanySlug(state, slug) {
    state.slug = slug;
  },

  setBenefits(state, benefits) {
    state.benefits = benefits;
  },

  setCompanySettings(state, settings) {
    state.slug = settings.slug;
    state.jobportalOnly = settings.jobportalOnly;
    state.showExternalAdvert = settings.showExternalAdvert;
    state.useGoogleAnalytics = settings.useGoogleAnalytics;
    state.googleAnalyticsId = settings.googleAnalyticsIdentifier;
    state.footerContent = settings.footerContent;
    state.jobsDefaultContent = settings.jobsDefaultContent;
    state.showAvailableFiltersOnly = settings.showAvailableFiltersOnly;
    state.homeSearchField = settings.homeSearchField;
    state.homeJobsButton = settings.homeJobsButton;

    if (settings.benefits) {
      state.benefits = settings.benefits;
    }

    if (settings.availableFilters) {
      state.filters = settings.availableFilters;
    }

    if (settings.availableDataFields) {
      state.jobDataFields = settings.availableDataFields;
    }

    if (settings.pageTitle) {
      state.pageTitle = settings.pageTitle;
    }

    if (settings.languages) {
      state.languages = settings.languages;
    }

    if (settings.customImprintUrl) {
      state.customImprintUrl = settings.customImprintUrl;
    }

    if (settings.customDataPrivacyUrl) {
      state.customDataPrivacyUrl = settings.customDataPrivacyUrl;
    }

    if (settings.defaultClaim) {
      state.defaultClaim = settings.defaultClaim;
    }

    if (settings.jobsContentPosition && settings.jobsContentPosition !== '') {
      state.jobsContentPosition = settings.jobsContentPosition;
    }
  },

  setInternal(state) {
    state.isInternal = true;
  }
};
