<template>
  <div
    class="image-box has-link"
    :class="{ 'has-description': hasDescription }"
    @click="$emit('click')"
  >
    <v-img :src="src" class="image-box__image">
      <v-overlay absolute :opacity="opacity"></v-overlay>
    </v-img>
    <div v-if="hasDescription" class="image-box__description">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },

    opacity: {
      type: Number,
      required: false,
      default: 0
    },

    to: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  computed: {
    hasDescription() {
      return !!this.$slots.description;
    }
  }
};
</script>

<style lang="scss" scoped>
.has-link:hover {
  cursor: pointer;
}
</style>
