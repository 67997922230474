var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joboffer-box",on:{"click":_vm.navigateToJob}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.localize(_vm.joboffer.name))+" ")])]),_c('div',{staticClass:"d-block d-sm-flex mt-4 text-sm"},[(
            _vm.isFieldEnabled('location') && _vm.localize(_vm.joboffer.location) !== ''
          )?_c('div',{staticClass:"joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"},[_c('v-icon',{staticClass:"d-inline"},[_vm._v("mdi-map-marker")]),_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.localize(_vm.joboffer.location))+" ")])],1):_vm._e(),(
            _vm.isFieldEnabled('company-name') &&
            _vm.localize(_vm.joboffer.companyName) !== ''
          )?_c('div',{staticClass:"joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"},[_c('v-icon',{staticClass:"d-inline"},[_vm._v("mdi-office-building-marker-outline")]),_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.localize(_vm.joboffer.companyName))+" ")])],1):_vm._e(),(
            _vm.isFieldEnabled('department') &&
            _vm.localize(_vm.joboffer.department) !== ''
          )?_c('div',{staticClass:"joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"},[_c('v-icon',{staticClass:"d-inline"},[_vm._v("mdi-briefcase")]),_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.localize(_vm.joboffer.department))+" ")])],1):_vm._e(),(
            _vm.isFieldEnabled('working-time') &&
            _vm.localize(_vm.joboffer.workingTime) !== ''
          )?_c('div',{staticClass:"joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"},[_c('v-icon',{staticClass:"d-inline"},[_vm._v("mdi-clock")]),_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.localize(_vm.joboffer.workingTime))+" ")])],1):_vm._e(),(
            _vm.isFieldEnabled('temp-job') && _vm.localize(_vm.joboffer.tempJob) !== ''
          )?_c('div',{staticClass:"joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"},[_c('v-icon',{staticClass:"d-inline"},[_vm._v("mdi-file")]),_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.localize(_vm.joboffer.tempJob))+" ")])],1):_vm._e()])]),_c('v-col',{staticClass:"d-flex align-center justify-end text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-chevron-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }