var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply-form__section apply-form__documents"},[_c('h3',[_vm._v("Ihre Dokumente")]),_c('v-alert',{attrs:{"type":"info","text":"","prominent":"","rounded":""}},[_vm._v(" Ihr Lebenslauf wird austomatisiert ausglesen. Dies beschleunigt die Bearbeitung der Bewerbung durch eine automatisierte Datenerfassung. ")]),(
      _vm.documentsSettings.cvValidationMode !== _vm.fieldValidationMode.NotPrompted
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('file-input',{attrs:{"label":_vm.getLabel('application.cv', _vm.documentsSettings.cvValidationMode),"accept":_vm.allowedFileTypes,"rules":_vm.getValidationRules(_vm.documentsSettings.cvValidationMode),"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.CV },"file-prefix":_vm.formId},on:{"fileUploaded":_vm.fileUploadedHandler},model:{value:(_vm.application.cv),callback:function ($$v) {_vm.$set(_vm.application, "cv", $$v)},expression:"application.cv"}})],1)],1):_vm._e(),(
      _vm.documentsSettings.letterValidationMode !==
      _vm.fieldValidationMode.NotPrompted
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('file-input',{attrs:{"label":_vm.getLabel(
            'application.letter',
            _vm.documentsSettings.letterValidationMode
          ),"accept":_vm.allowedFileTypes,"rules":_vm.getValidationRules(_vm.documentsSettings.letterValidationMode),"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.Letter },"file-prefix":_vm.formId},model:{value:(_vm.application.letter),callback:function ($$v) {_vm.$set(_vm.application, "letter", $$v)},expression:"application.letter"}})],1)],1):_vm._e(),(
      _vm.documentsSettings.documentsValidationMode !==
      _vm.fieldValidationMode.NotPrompted
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('file-input',{attrs:{"multiple":"","label":_vm.getLabel(
            'application.additionalDocuments',
            _vm.documentsSettings.documentsValidationMode
          ),"accept":_vm.allowedFileTypes,"rules":_vm.getValidationRules(_vm.documentsSettings.documentsValidationMode),"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.Document },"file-prefix":_vm.formId},model:{value:(_vm.application.documents),callback:function ($$v) {_vm.$set(_vm.application, "documents", $$v)},expression:"application.documents"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('em',{staticClass:"muted"},[_vm._v("Maximale Dateigröße: 15 MB je Datei")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }