<template>
  <div class="dense content-box alternate text-left">
    <div class="sidebar-filter">
      <div class="d-flex sidebar-filter__head align-center">
        <div class="flex-grow-1 sidebar-filter__title">
          <v-icon class="mr-2 large" color="primary">mdi-tune</v-icon>
          <span>Filter</span>
        </div>

        <div>
          <v-btn small fab color="primary" @click="clearAllFilters">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <v-row v-if="filters.includes('locations')" class="mt-8">
        <v-col cols="12">
          <strong class="sidebar-filter__filter-label">{{
            $t('jobs.location')
          }}</strong>
          <div
            v-for="location in sortedLocations"
            :key="`location_filter_${location.id}`"
          >
            <v-checkbox
              v-model="locationFilters"
              :label="`${localize(location.name)} (${location.numJobs})`"
              :value="location"
              hide-details
            >
            </v-checkbox>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="filters.includes('departments')" class="mt-4">
        <v-col cols="12">
          <strong class="sidebar-filter__filter-label">{{
            $t('jobs.department')
          }}</strong>
          <div
            v-for="department in sortedDepartments"
            :key="`department_filter_${department.id}`"
          >
            <v-checkbox
              v-model="departmentFilters"
              :label="`${localize(department.name)} (${department.numJobs})`"
              :value="department"
              hide-details
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="filters.includes('career-levels')" class="mt-4">
        <v-col cols="12">
          <strong class="sidebar-filter__filter-label">{{
            $t('jobs.careerLevel')
          }}</strong>
          <div
            v-for="careerLevel in sortedCareerLevels"
            :key="`career_level_filter_${careerLevel.id}`"
          >
            <v-checkbox
              v-model="careerLevelFilters"
              :label="`${localize(careerLevel.name)} (${careerLevel.numJobs})`"
              :value="careerLevel"
              hide-details
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="filters.includes('working-times')" class="mt-4">
        <v-col cols="12">
          <strong class="sidebar-filter__filter-label">{{
            $t('jobs.workingTime')
          }}</strong>
          <div
            v-for="workingTime in sortedWorkingTimes"
            :key="`working_time_filter_${workingTime.id}`"
          >
            <v-checkbox
              v-model="workingTimeFilters"
              :label="`${localize(workingTime.name)} (${workingTime.numJobs})`"
              :value="workingTime"
              hide-details
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

export default {
  data() {
    return {
      showDialog: false,
      locationFilters: [],
      departmentFilters: [],
      careerLevelFilters: [],
      workingTimeFilters: []
    };
  },

  computed: {
    ...mapState('jobsModule', {
      activeFilters: 'filters'
    }),

    ...mapGetters('jobsModule', {
      locations: 'getLocations',
      departments: 'getDepartments',
      careerLevels: 'getCareerLevels',
      workingTimes: 'getWorkingTimes'
    }),

    ...mapState('companyModule', {
      filters: 'filters'
    }),

    hasActiveFilters() {
      return Object.entries(this.activeFilters).length > 0;
    },

    sortedCareerLevels() {
      return [...this.careerLevels].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedDepartments() {
      return [...this.departments].sort((a, b) => {
        return this.localize(a.name).localeCompare(this.localize(b.name));
      });
    },

    sortedLocations() {
      return [...this.locations].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedWorkingTimes() {
      return [...this.workingTimes].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    }
  },

  watch: {
    locationFilters() {
      this.clearFilters('locationId');

      for (const location of this.locationFilters) {
        this.setFilter({
          field: 'locationId',
          value: location.id,
          name: location.name
        });
      }
    },

    departmentFilters() {
      this.clearFilters('departmentId');

      for (const department of this.departmentFilters) {
        this.setFilter({
          field: 'departmentId',
          value: department.id,
          name: department.name
        });
      }
    },

    careerLevelFilters() {
      this.clearFilters('careerLevelId');

      for (const careerLevel of this.careerLevelFilters) {
        this.setFilter({
          field: 'careerLevelId',
          value: careerLevel.id,
          name: careerLevel.name
        });
      }
    },

    workingTimeFilters() {
      this.clearFilters('workingTimeId');

      for (const workingTime of this.workingTimeFilters) {
        this.setFilter({
          field: 'workingTimeId',
          value: workingTime.id,
          name: workingTime.name
        });
      }
    }
  },

  mounted() {
    this.setCurrentFilters();
    eventBus.$on('filtersCleared', this.onFiltersCleared);
  },

  methods: {
    ...mapActions('jobsModule', {
      clearFilters: 'clearFilters',
      setFilter: 'setFilter'
    }),

    clearAllFilters() {
      this.clearFilters();
    },

    onFiltersCleared(field) {
      if (!field) {
        this.locationFilters = [];
        this.departmentFilters = [];
        this.careerLevelFilters = [];
        this.workingTimeFilters = [];
      }
    },

    setCurrentFilters() {
      if (!this.filters || !this.activeFilters) {
        return;
      }

      if (
        this.filters.includes('departments') &&
        this.activeFilters['departmentId']
      ) {
        const departmentIds = this.activeFilters['departmentId'].map(
          (x) => x.value
        );
        this.departmentFilters = this.departments.filter((x) =>
          departmentIds.includes(x.id)
        );
      }

      if (
        this.filters.includes('locations') &&
        this.activeFilters['locationId']
      ) {
        const locationIds = this.activeFilters['locationId'].map(
          (x) => x.value
        );
        this.locationFilters = this.locations.filter((x) =>
          locationIds.includes(x.id)
        );
      }
    }
  }
};
</script>
