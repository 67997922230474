<template>
  <div class="content-grid__wrapper">
    <div class="content-grid">
      <div
        v-for="(item, index) in items"
        :key="`grid_item_${index}`"
        :class="`content-grid__item ${getClass(item.route)}`"
        :style="`
          background-image: url(${apiUrl}/file/public?slug=${slug}&fileName=${
          item.image
        });
          grid-column: col ${item.colStart} / span ${item.colSpan};
          grid-row: row ${item.rowStart} / span ${item.rowSpan};
          aspect-ratio: ${getAspectRatio(item.colSpan, item.rowSpan)};
        `"
        @click="goto(item.route)"
      >
        <div class="content-grid__item__label">
          <h3>{{ localize(item.label) }}</h3>
        </div>

        <div class="content-grid__item__overlay">
          <h3>{{ localize(item.label) }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    items: [
      {
        colStart: 1,
        colSpan: 4,
        rowStart: 1,
        rowSpan: 4,
        image: 'employer.jpg',
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Biotest als Arbeitgeber'
          },
          {
            languageKey: 'en-US',
            translation: 'Biotest - Your employer'
          }
        ],
        route: {
          name: 'contentSite',
          params: {
            siteName: 'biotest-als-arbeitgeber'
          }
        }
      },
      {
        colStart: 5,
        colSpan: 4,
        rowStart: 1,
        rowSpan: 4,
        image: 'benefits.jpg',
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Benefits'
          },
          {
            languageKey: 'en-US',
            translation: 'Benefits'
          }
        ],
        route: {
          name: 'contentSite',
          params: {
            siteName: 'benefits'
          }
        }
      },
      {
        colStart: 9,
        colSpan: 4,
        rowStart: 1,
        rowSpan: 4,
        image: 'apply.jpg',
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Bewerbungsprozess'
          },
          {
            languageKey: 'en-US',
            translation: 'Application process'
          }
        ],
        route: {
          name: 'contentSite',
          params: {
            siteName: 'bewerbungsprozess'
          }
        }
      },
      {
        colStart: 1,
        colSpan: 4,
        rowStart: 5,
        rowSpan: 4,
        image: 'ausbildung.jpg',
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Berufsausbildung'
          },
          {
            languageKey: 'en-US',
            translation: 'Apprenticeship'
          }
        ],
        route: {
          name: 'contentSite',
          params: {
            siteName: 'berufsausbildung'
          }
        }
      },
      {
        colStart: 5,
        colSpan: 4,
        rowStart: 5,
        rowSpan: 4,
        image: 'praktikum.jpg',
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Praktikum'
          },
          {
            languageKey: 'en-US',
            translation: 'Internship'
          }
        ],
        route: {
          name: 'contentSite',
          params: {
            siteName: 'praktika-und-abschlussarbeiten'
          }
        }
      },

      {
        colStart: 9,
        colSpan: 4,
        rowStart: 5,
        rowSpan: 4,
        image: 'boss.jpg',
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Fach- und Führungskräfte'
          },
          {
            languageKey: 'en-US',
            translation: 'Professionals and managers'
          }
        ],
        route: {
          name: 'contentSite',
          params: {
            siteName: 'fach-und-fuehrungskraefte'
          }
        }
      }
    ]
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  methods: {
    getAspectRatio(colSpan, rowSpan) {
      return colSpan / rowSpan;
    },

    goto(routeData) {
      if (!routeData) {
        return;
      }

      this.$router.push({
        name: routeData.name,
        params: routeData.params
      });
    },

    getClass(routeData) {
      return routeData ? 'has-link' : '';
    }
  }
};
</script>
