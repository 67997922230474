<template>
  <section>
    <content-area v-if="siteContent" :content="siteContent"></content-area>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import contentArea from '@/components/content/content-area.vue';

export default {
  name: 'HomeView',
  data: () => ({
    departments: [],
    apiUrl: process.env.VUE_APP_API_URL,
    siteContent: null
  }),

  computed: {
    ...mapState({
      routeImages: 'routeImages'
    }),

    ...mapState('companyModule', {
      slug: 'slug',
      jobportalOnly: 'jobportalOnly'
    })
  },

  async created() {
    const routeImage = this.routeImages['home'];
    if (routeImage) {
      this.setHeroImage(routeImage);
    }

    this.departments = await this.loadDepartments(this.slug);
  },

  async mounted() {
    const request = {
      slug: this.$route.params.slug,
      siteName: 'home'
    };

    const siteResponse = await this.getSite(request);
    const siteData = await siteResponse.json();
    this.siteContent = siteData.content;
  },

  methods: {
    ...mapActions({
      clearJobsFilter: 'jobsModule/clearFilters',
      loadDepartments: 'companyModule/loadDepartments',
      setJobsFilter: 'jobsModule/setFilter'
    }),

    ...mapActions('sitesModule', {
      getSite: 'getSite'
    }),

    ...mapMutations({
      setHeroImage: 'setHeroImage',
      setJobsContent: 'jobsModule/setContent'
    }),

    departmentClicked(department) {
      this.clearJobsFilter();
      this.setJobsFilter({
        field: 'departmentId',
        value: department.id,
        name: department.name
      });

      this.$router.push({ name: 'jobs' });
    }
  },

  components: {
    contentArea
  }
};
</script>
