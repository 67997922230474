<template>
  <section :class="siteId">
    <content-area v-if="siteContent" :content="siteContent"></content-area>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import contentArea from '@/components/content/content-area.vue';

export default {
  data: () => ({
    siteContent: null,
    siteId: ''
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  async mounted() {
    const companySlug = this.slug ?? this.$route.params.slug;

    const request = {
      slug: companySlug,
      siteName: this.$route.params.siteName
    };

    const siteResponse = await this.getSite(request);
    const siteData = await siteResponse.json();
    this.siteContent = siteData.content;
    this.siteId = siteData.siteId;
  },

  methods: {
    ...mapActions('sitesModule', {
      getSite: 'getSite'
    })
  },

  components: {
    contentArea
  }
};
</script>
