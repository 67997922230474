var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-grid__wrapper"},[_c('div',{staticClass:"content-grid"},_vm._l((_vm.items),function(item,index){return _c('div',{key:`grid_item_${index}`,class:`content-grid__item ${_vm.getClass(item.route)}`,style:(`
        background-image: url(${_vm.apiUrl}/file/public?slug=${_vm.slug}&fileName=${
        item.image
      });
        grid-column: col ${item.colStart} / span ${item.colSpan};
        grid-row: row ${item.rowStart} / span ${item.rowSpan};
        aspect-ratio: ${_vm.getAspectRatio(item.colSpan, item.rowSpan)};
      `),on:{"click":function($event){return _vm.goto(item.route)}}},[_c('div',{staticClass:"content-grid__item__label"},[_c('h3',[_vm._v(_vm._s(_vm.localize(item.label)))])]),_c('div',{staticClass:"content-grid__item__overlay"},[_c('h3',[_vm._v(_vm._s(_vm.localize(item.label)))])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }