<template>
  <div>
    <component
      v-for="(element, index) in content"
      :key="`content_element_${index}`"
      :is="getElementComponent(element)"
      :data="element"
    ></component>
  </div>
</template>

<script>
import { ContentEnums } from '@/enum/content.js';

import textBox from './text-box.vue';
import advertTextBox from './advert-text-box.vue';
import companyBenefits from './company-benefits.vue';
import htmlContent from './html-content.vue';
import contentGrid from './content-grid.vue';
import companyDepartments from './company-departments.vue';
import expanderContent from './expander-content.vue';
import accordionContent from './accordion-content.vue';
import imageContent from './image-content.vue';

export default {
  name: 'content-area',
  props: {
    content: {
      type: Array,
      required: true
    }
  },

  methods: {
    getElementComponent(contentElement) {
      if (
        contentElement.componentType === ContentEnums.componentTypes.TextBox
      ) {
        return textBox;
      }

      if (
        contentElement.componentType ===
        ContentEnums.componentTypes.AdvertTextBox
      ) {
        return advertTextBox;
      }

      if (
        contentElement.componentType ===
        ContentEnums.componentTypes.CompanyBenefits
      ) {
        return companyBenefits;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.HtmlContent
      ) {
        return htmlContent;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.ContentGrid
      ) {
        return contentGrid;
      }

      if (
        contentElement.componentType ===
        ContentEnums.componentTypes.CompanyDepartments
      ) {
        return companyDepartments;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.Expander
      ) {
        return expanderContent;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.Accordion
      ) {
        return accordionContent;
      }

      if (contentElement.componentType === ContentEnums.componentTypes.Image) {
        return imageContent;
      }

      return '';
    }
  }
};
</script>
