<template>
  <div>
    <questions-chapter
      v-model="questions"
      v-for="chapterIndex in chapterIndexes"
      class="questions-form"
      :key="`question_chapter_${chapterIndex}`"
      :chapter-index="chapterIndex"
      :chapter-title="getChapterTitle(chapterIndex)"
      :short-apply="shortApply"
    ></questions-chapter>
  </div>
</template>

<script>
import questionsChapter from './questions-chapter.vue';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    shortApply: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    chapterIndexes() {
      const indexes = this.value
        .map((x) => x.chapterIndex)
        .sort((a, b) => a - b);
      return [...new Set(indexes)];
    },

    questions: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    getChapterTitle(chapterIndex) {
      const chapterQuestion = this.questions.find(
        (x) => x.chapterIndex === chapterIndex
      );
      return chapterQuestion.chapterLabel;
    }
  },

  components: {
    questionsChapter
  }
};
</script>
